@import "./variables.scss";

.App {
  background-image: url("../src/assets/iobackground.png");
  background-size: cover;
  height: 100vh;
  &__text-grid {
    position: absolute;
    top: 0;
  }

  &__title {
    // position: relative;
    // top: -200px;
    color: $primarytext;
    // z-index: 0;
    padding: 24px;
  }

  &__footer {
    position: absolute;
    color: $primarytext;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
  }

  .ioheader__nav-item {
    // margin-right: 24px;
    font-family: "BemboStd" !important;
    text-transform: uppercase;
    font-size: 20px;
  }

  .ioheader__list {
    display: flex;
    justify-content: space-around;
    width: 60%;
  }

  @media (max-width: 768px) {
    .ioheader__nav-item {
      font-size: 13px;
    }
  }

  // @media (max-width: 768px) {
  //   .ioheader__nav {
  //     flex-direction: column;
  //   }
  // }

  @media (max-width: 768px) {
    .ioheader__list {
      width: 100%;
    }
  }

  &__carousel-container {
    overflow: hidden;
    width: 100%;
    height: 100vh;
    position: relative;
  }

  &__carousel-track {
    height: 100%;
    display: flex;
    animation: moveLeft 300s linear infinite;
  }

  &__carousel-track img {
    height: 100%;
  }

  .view-open {
    background-color: white;
    color: black;
  }

  .ioheader__extend {
    background-color: white !important;
    color: black !important;
  }

  .ioheader__link {
    color: black !important;
  }
  .ioheader__description {
    color: rgb(76, 75, 75) !important;
  }

  .ioheader__username {
    font-size: 12px;
  }

  .ioheader__amqlogo {
    margin-top: 5px;
  }

  @media (max-width: 768px) {
    .ioheader__username {
      display: none;
    }
  }

  @media (max-width: 768px) {
    .ioheader__description {
      font-size: 11px;
    }
  }

  @media (max-width: 768px) {
    .ioheader__amqlogo {
      // margin-top: 24px;
      margin-left: 24px;
      height: 10px;
    }
  }
}

.ioheader__extend {
  z-index: 1000;
}

@import "./font/MyFontsWebfontsKit.css";

a {
    text-decoration: none;
    color: inherit;
}

body {
    margin: 0;
    padding: 0;
    font-family: "HelveticaNeueLTStd-Lt";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #fff;
}

li {
    list-style-type: none;
}

h2 {
    font-size: 24px;
    font-weight: 500;
    text-align: center;
}

h3 {
    font-size: 16px;
    color: #000;
    font-family: "BemboStd";
    font-weight: 500;
    text-align: center;
}

p {
    font-size: 14px;
    color: #000;
    font-family: "HelveticaNeueLTStd-Lt";
}

td {
    font-size: 14px;
    color: #000;
    font-family: "HelveticaNeueLTStd-Lt" !important;
}

th {
    font-family: "HelveticaNeueLTStd-Md" !important;
    color: #747474 !important;
}

.selected {
    font-weight: bold;
}

.emphasis {
    font-weight: bold;
}

ul {
    padding-inline-start: 0px;
}

/**
 * @license
 * MyFonts Webfont Build ID 3769356, 2019-06-03T09:02:57-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: HelveticaNeueLTStd-Lt by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/neue-helvetica/pro-45-light-189171/
 * Copyright: Copyright &#x00A9; 2014 Monotype Imaging Inc. All rights reserved.
 * 
 * Webfont: HelveticaNeueLTStd-Md by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/neue-helvetica/pro-65-medium-189171/
 * Copyright: Copyright &#x00A9; 2014 Monotype Imaging Inc. All rights reserved.
 * 
 * Webfont: BemboStd by Monotype 
 * URL: https://www.myfonts.com/fonts/mti/bembo/std-roman/
 * Copyright: Copyright &#x00A9; 2014 The Monotype Corporation. All rights reserved.
 * 
 * 
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3769356
 * Licensed pageviews: 80,000,000
 * 
 * © 2019 MyFonts Inc
*/

/* @import must be at top of file, otherwise CSS will not work */
@import url('//hello.myfonts.net/count/39840c');

@font-face {
  font-family: 'HelveticaNeueLTStd-Lt';
  src: url('webfonts/39840C_0_0.eot');
  src: url('webfonts/39840C_0_0.eot?#iefix') format('embedded-opentype'),
    url('webfonts/39840C_0_0.woff2') format('woff2'), url('webfonts/39840C_0_0.woff') format('woff'),
    url('webfonts/39840C_0_0.ttf') format('truetype'),
    url('webfonts/39840C_0_0.svg#wf') format('svg');
}

@font-face {
  font-family: 'HelveticaNeueLTStd-Md';
  src: url('webfonts/39840C_1_0.eot');
  src: url('webfonts/39840C_1_0.eot?#iefix') format('embedded-opentype'),
    url('webfonts/39840C_1_0.woff2') format('woff2'), url('webfonts/39840C_1_0.woff') format('woff'),
    url('webfonts/39840C_1_0.ttf') format('truetype'),
    url('webfonts/39840C_1_0.svg#wf') format('svg');
}

@font-face {
  font-family: 'BemboStd';
  src: url('webfonts/39840C_2_0.eot');
  src: url('webfonts/39840C_2_0.eot?#iefix') format('embedded-opentype'),
    url('webfonts/39840C_2_0.woff2') format('woff2'), url('webfonts/39840C_2_0.woff') format('woff'),
    url('webfonts/39840C_2_0.ttf') format('truetype'),
    url('webfonts/39840C_2_0.svg#wf') format('svg');
}
